<template>
    <div style="height: 100%">
        <!--      头部      -->
        <bread></bread>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <div class="table-top">
                    <div class="iq-search-bar">
                        <el-form action="#" class="searchbox" :inline="true">
                            <el-form-item>
                                <el-input v-model="Keyword" type="text" size="small" placeholder="退单编号"
                                    @keyup.enter.native="search" clearable ></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" size="small" @click="search" plain>搜索</el-button>
                            </el-form-item>
                            <el-form-item>
                                <download-excel v-if="tableData.length>0" :data="tableData" 
                                    :fields="json_fields" worksheet="My Worksheet" :header="title"
                                    :name="`${title}.xls`" class="excel">
                                    <el-button type="warning" size="mini" class="el-icon-download">导出当前数据</el-button>
                                </download-excel>
                            </el-form-item>
                            <el-form-item>
                                <download-excel v-if="DataCount" :fields="json_fields" :fetch="fetchData"
                                    worksheet="My Worksheet" :header="title" :name="`${title}_All.xls`" class="excel">
                                    <el-button type="danger" size="mini" class="el-icon-download">导出全部数据</el-button>
                                </download-excel>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <el-table :data="tableData" height="calc(100% - 80px)" border style="width: 100%" :header-cell-style="{background:'#F5F7FC'}">
                    <el-table-column prop="refundCode" label="退单编号"  width="240" fixed></el-table-column>
                    <el-table-column label="商品名称" align="center" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{ scope.row.commodity.name }}
                        </template>
                    </el-table-column>
                    <el-table-column label="退款金额（元）" align="center">
                        <template slot-scope="scope">
                            ￥{{ scope.row.refundMoney }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="userName" label="下单用户" width="200" align="center">
                        <template slot-scope="scope">
                            <p>{{ scope.row.userName }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="remark" label="申请退款理由" width="200" align="center"></el-table-column>
                    <el-table-column prop="refundTime" label="申请时间" width="200px" align="center">
                        <template slot-scope="scope">
                            <p>{{ scope.row.refundTime | timefilters }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="退单状态" align="center">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.state == 0" type="warning" size="small">退款中,商家待确认</el-tag>
                            <el-tag v-if="scope.row.state == 10" type="warning" size="small">商家同意退款，平台受理中</el-tag>
                            <el-tag v-if="scope.row.state == 11" type="success" size="small">退款完成</el-tag>
                            <el-tag v-if="scope.row.state == 20" type="primary" size="small">平台介入</el-tag>
                            <el-tag v-if="scope.row.state == -10" type="danger" size="small">商家拒绝</el-tag>
                            <el-tag v-if="scope.row.state == -11" type="danger" size="small">平台拒绝</el-tag>
                            <el-tag v-if="scope.row.state == -999" type="info" size="small">取消</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="80px" align="center">
                        <template slot-scope="scope">
                            <span class="primary optionBtn" @click="openDetail(scope.row)">详情</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex"
                    :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
            </div>
        </div>
        <el-drawer title="退单详情" :visible.sync="DetailDrawer" direction="rtl" size="550px">
            <div class="drawer-box">
                <div class="detail-item">
                    <div class="status">
                        <p class="text-orange" v-if="refuseDetail.state == 0">退款中,商家待确认</p>
                        <p class="text-orange" v-if="refuseDetail.state == 10">商家同意退款，平台受理中</p>
                        <p class="text-succes" v-if="refuseDetail.state == 11">退款完成</p>
                        <p class="text-orange" v-if="refuseDetail.state == 20">平台介入</p>
                        <p class="text-danger" v-if="refuseDetail.state == -10">商家拒绝</p>
                        <p class="text-danger" v-if="refuseDetail.state == -11">平台拒绝</p>
                        <p class="text-orange" v-if="refuseDetail.state == -999">取消</p>
                    </div>
                    <div class="block">
                        <el-timeline>
                            <el-timeline-item v-for="(item, index) in refuseDetail.refundDetails" :key="index"
                                color="#FFA500" :timestamp="item.createdTime | timefilters">
                                <span class="status-color" v-if="item.state == 0 && item.type == 0">申请退款</span>
                                <span class="status-color" v-if="item.state == 1 && item.type == 1">商家已同意</span>
                                <span class="status-color" v-if="item.state == -1 && item.type == 1">商家已拒绝</span>
                                <span class="status-color" v-if="item.state == 1 && item.type == 3">退款完成</span>
                                <span class="status-color" v-if="item.state == 0 && item.type == 2">平台介入</span>
                                <span class="status-color" v-if="item.state == 1 && item.type == 2">平台通过</span>
                                <span class="status-color" v-if="item.state == -1 && item.type == 2">平台拒绝</span>
                                <p v-if="item.content" class="resultResc">处理结果：{{ item.content }}</p>
                            </el-timeline-item>
                        </el-timeline>
                        <!--<el-timeline>
                            <el-timeline-item v-for="(item,index) in refuseDetail.refundDetails" :key="index" :timestamp="item.state" placement="top">
                                <el-card>
                                    <h4>更新 Github 模板</h4>
                                    <p>王小虎 提交于 {{item.createdTime | timefilters}}</p>
                                </el-card>
                            </el-timeline-item>
                        </el-timeline>-->
                    </div>
                </div>
                <div class="detail-item">
                    <h5 class="detail-tit">商品信息</h5>
                    <div class="pro-box" v-if="refuseDetail.commodity">
                        <div class="search-item clearfix">
                            <div class="item-img">
                                <img v-if="refuseDetail.commodity.coverPicture.length>0" :src="Services.Shopping + refuseDetail.commodity.coverPicture[0].filePath">
                                <img src="../../../../assets/img/no-img.png" v-else/>
                            </div>
                            <div class="item-mes">
                                <p class="item-tit">
                                    {{ refuseDetail.commodity.name }}
                                </p>
                                <!-- <p class="item-price mgt-2 text-red">￥{{ refuseDetail.actualMoney }}</p>
                                <p class="text-gray shopcart">*{{ refuseDetail.commodityNum }}</p> -->
                            </div>
                        </div>
                        <!-- <div class="clearfix mgt-2">
                            <p class="left">快递运费</p>
                            <p class="right text-red">￥{{ refuseDetail.orderCommodityDetail.freight }}</p>
                        </div>
                        <div class="clearfix mgt-2">
                            <p class="left">已优惠</p>
                            <p class="right text-red">￥{{ refuseDetail.orderCommodityDetail.preferentialMoney }}</p>
                        </div> -->
                        <div class="clearfix mgt-2">
                            <p class="left">实付</p>
                            <p class="right text-red">￥{{ refuseDetail.orderCommodityDetail.payMoney }}</p>
                        </div>
                    </div>
                </div>
                <div class="detail-item">
                    <h5 class="detail-tit">退单信息</h5>
                    <p><span>申请时间：</span>{{ refuseDetail.refundTime | timefilters }}</p>
                    <p><span>退单原因：</span>{{ refuseDetail.remark }}</p>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import bread from "../../../../components/common/breadcrumb";
import { Refund } from "../../../../components/HospitalDomain/ShopMall/Refund";

export default {
    name: "RefundList",
    components: {
        bread
    },
    data() {
        var refund = new Refund(this.TokenClient, this.Services.Shopping);
        return {
            refundDomain: refund,
            Keyword: '',
            tableData: [],
            PageIndex: 1,
            PageTotal: 1,
            PageSize: 0,
            DataTotal: 1,
            DetailDrawer: false,
            refuseDetail: {},
            http: '',
            title: '退单记录',
            DataCount:0,
            json_fields: {
                "退单编号": {
                    filed: 'refundCode',
                    callback: val => {
                        return 'NO：' + val.refundCode
                    }
                },
                "商品名称": {
                    callback: (val) => {
                        return val.commodity.name
                    }
                },
                "退款金额": 'refundMoney',
                "下单用户": 'userName',
                "申请退款理由": 'remark',
                "申请时间": {
                    filed: 'refundTime',
                    callback: (val) => {
                        var val = val.refundTime
                        return this.$fliters.timefilters(val)
                    }
                },
                "退单状态": {
                    callback: val => {
                        var str = ''
                        var val = val.state
                        switch (val) {
                            case 0:
                                str = '退款中,商家待确认'
                                break;
                            case 10:
                                str = '商家同意退款，平台受理中'
                                break;
                            case 11:
                                str = '退款完成'
                                break;
                            case 20:
                                str = '平台介入'
                                break;
                            case -10:
                                str = '商家拒绝'
                                break;
                            case -11:
                                str = '平台拒绝'
                                break;
                            case -999:
                                str = '取消'
                                break;
                        }
                        return str
                    }
                },
            },
        }
    },
    mounted() {
        this.getList();
    },
    methods: {
        search() {
            this.PageIndex = 1;
            this.getList();
        },
        ChangePage(pageIndex) {
            this.PageIndex = pageIndex;
            this.getList();
        },
        async fetchData() {
            var _this = this;
            return await _this.getAllData()
        },
        getAllData() {
            var _this = this;
            return new Promise((resolve, reject) => {
                _this.refundDomain.RefundList(1, _this.Keyword, _this.DataCount,
                    function (data) {
                        resolve(data.data.results);
                    },
                    function (err) {
                        resolve('');
                        console.log(err);
                    });
            })

        },
        getList() {
            var _this = this;
            _this.refundDomain.RefundList(_this.PageIndex, _this.Keyword,null,
                function (data) {
                    _this.tableData = data.data.results;
                    _this.PageIndex = data.data.pageIndex;
                    _this.PageSize = data.data.pageSize;
                    _this.PageTotal = data.data.pageCount;
                    _this.DataTotal = data.data.dataTotal;
                    if (!_this.Keyword) { 
                        _this.DataCount = data.data.dataTotal;
                    }
                },
                function (err) {
                    console.log(err);
                });
        },
        openDetail(item) {
            var _this = this;
            _this.DetailDrawer = true;
            _this.refundDomain.RefundDetail(item.id,
                function (data) {
                    _this.refuseDetail = data.data;
                },
                function (err) {
                    console.log(err);
                });
        }

    }
}
</script>

<style scoped>

.navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.navbar {
    position: relative;
    height: 73px;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.header {
    flex: 1;
    text-align: right;
    margin-right: 15px;
}

.messages {
    margin-top: 13px;
    margin-right: 20px;
}

.messages .el-icon-bell {
    font-size: 26px;
}

/*content*/


.table-top {
    margin-bottom: -10px;
}

.iq-search-bar {
    display: inline-block;
    margin-right: 20px;
}

.iq-search-bar .searchbox {
    position: relative;
}

.iq-search-bar .searchbox .el-input {
    width: 300px;
}

.iq-search-bar .searchbox .el-input {
    width: 300px;
}

.iq-search-bar .searchbox .search-link {
    position: absolute;
    right: 15px;
    top: 2px;
    font-size: 16px;
}

.iq-search-bar .search-input {
    width: 300px;
    padding: 5px 40px 5px 15px;
    margin-right: 10px;
    border-radius: 5px;
    border: none;
    background: #ceebee;
    line-height: 1.8;
}

.drawer-box {
    padding: 0 30px;
}

.pro-box {
    margin-top: 15px;
    padding: 10px;
    border-radius: 8px;
    background-color: #f5f7fa;
}

.item-img {
    float: left;
    width: 118px;
    height: 118px;
    margin-right: 10px;
    overflow: hidden;
}

.item-img img {
    width: 100%;
}

.item-mes {
    width: calc(100% - 130px);
    float: left;
    position: relative;
}

.item-tit {
    color: #454545;
    font-size: 14px;
    overflow: hidden;
    text-align: left;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    height: 57px;
}

.item-price {
    margin-top: 40px;
}

.shopcart {
    position: absolute;
    right: 0;
    bottom: 0;
}

.detail-item {
    position: relative;
    margin-bottom: 20px;
}

.detail-item .detail-tit {
    font-size: 18px;
}

.detail-item>p {
    margin: 5px 0;
}

.detail-item>p span {
    display: inline-block;
    width: 90px;
    text-align: left;
}

.detail-item .status {
    position: absolute;
    right: 0;
}

.detail-item .status .text-orange {
    color: orange;
}

.detail-item .status p.text-danger {
    color: red;
}

.detail-item .status p.text-succes {
    color: forestgreen;
}

.status-color {
    color: orange;
}
.resultResc{
    font-size: 12px;
}
</style>

