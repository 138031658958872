let Refund = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;


    /**
     * 待审核退单列表
     * @param pageIndex
     * @param keyWord
     * @param success
     * @param error
     * @constructor
     */
    this.AuditRefund = function ( pageIndex, keyWord, success, error) {
        var url = ServiceBaseAddress + '/api/Refund/ManagerAuditPages';
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (keyWord ) {
            url += `/${keyWord }`;
        }else {
            url += `/%20`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 退单记录
     * @param pageIndex
     * @param keyWord
     * @param success
     * @param error
     * @constructor
     */
    this.RefundList = function ( pageIndex, keyWord,pageSize, success, error) {
        var url = ServiceBaseAddress + '/api/Refund/ManagerPages';
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (keyWord ) {
            url += `/${keyWord }`;
        }else {
            url += `/%20`;
        }
        if (pageSize) {
            url += `/${pageSize}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }


    /**
     * 退款详情
     * @param refundId
     * @param success
     * @param error
     * @constructor
     */
    this.RefundDetail = function (refundId , success, error) {
        var url = ServiceBaseAddress + '/api/Refund/Org/Detail/'+ refundId ;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }


    /**
     * 同意退款申请
     * @param refundId
     * @param success
     * @param error
     * @constructor
     */
    this.AgreeRefund = function (refundId, success, error) {
        var url = ServiceBaseAddress + '/api/Refund/OrganizationAgree/' + refundId ;
        TokenClient.Post(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 拒绝退款申请
     * @param item
     * @param success
     * @param error
     * @constructor
     */
    this.RefuseRefund = function (item, success, error) {
        var url = ServiceBaseAddress + '/api/Refund/OrganizationRefuse';
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);

    }

    /**
     * 平台介入--记录
     * @param pageIndex
     * @param keyWord
     * @param success
     * @param error
     * @constructor
     */
    this.PlatformInterventionList = function ( pageIndex, keyWord, success, error) {
        var url = ServiceBaseAddress + '/api/Refund/PlatformInterventionPages';
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (keyWord ) {
            url += `/${keyWord }`;
        }else {
            url += `/%20`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }


    /**
     * 平台介入--同意退款申请
     * @param refundId
     * @param item
     * @param success
     * @param error
     * @constructor
     */
    this.AgreePlatform = function (refundId  , item, success, error) {
        var url = ServiceBaseAddress + '/api/Refund/PlatformAgree/' + refundId ;
        TokenClient.Post(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 平台介入--拒绝退款申请
     * @param item
     * @param success
     * @param error
     * @constructor
     */
    this.RefusePlatform = function (item, success, error) {
        var url = ServiceBaseAddress + '/api/Refund/PlatformRefuse';
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);

    }
}
export { Refund }
